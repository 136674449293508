import { Gender, type PublicPlayerDealResponsePagedData } from '@on3/api';
import { useSite } from '@on3/ui-lib/index';
import { formatCurrency } from '@on3/ui-lib/utils/formatters';
import clsx from 'clsx';
import { SportIcon, SportName } from 'components/Icons/SportIcon';
import { On3Avatar } from 'components/Image/On3Avatar';
import { AthleteVerified } from 'components/UI/AthleteVerified/AthleteVerified';
import { SidebarWrapper } from 'components/UI/Layout/Containers/Sidebar/SidebarWrapper';
import { TextLink } from 'components/UI/Link/TextLink';
import { StarRating } from 'components/UI/StarRating/StarRating';
import { Text } from 'components/UI/Typography/Text';
import { format } from 'date-fns';

import styles from './DealsHomeModule.module.scss';
import { getSourceUrl } from './DealTracker/DealTrackerItems/helper';
import {
  divisionAbbreviation,
  getAbbreviatedRank,
  getNILProfileLink,
} from './helpers';

interface IDealsHomeModule extends PublicPlayerDealResponsePagedData {
  viewAllLink?: string;
  gender?: Gender;
}
export const DealsHomeModule = ({
  list,
  viewAllLink,
  gender,
}: IDealsHomeModule) => {
  const { currentSite } = useSite();
  const title =
    gender === Gender.Womens ? "Women's NIL Deal Tracker" : 'NIL Deal Tracker';

  return (
    <SidebarWrapper className={styles.sidebarWrapper}>
      <header>
        <Text className={styles.titleText} variant="h6">
          {title}
        </Text>
      </header>
      <ul className={styles.list}>
        {list?.map((player) => {
          const {
            rating,
            person,
            company,
            sourceUrl,
            rosterRating,
            status,
            date,
            nilValue,
          } = player;
          const statusTeam = status?.committedAsset || status?.transferredAsset;
          const ratingTeamLogo = {
            src:
              statusTeam?.assetUrl || 'https://on3static.com/teams/default.svg',
            altText: statusTeam?.name || 'ranking logo',
          };

          const inCollege = !person?.division?.includes('High');

          // TODO: We need to make sure sport is coming back in the person response
          const sportName = (person?.defaultSport?.name ||
            rating?.sport?.name) as SportName;
          const isFootballAthlete = sportName === 'Football';
          const isBasketballAthlete = sportName === 'Basketball';
          const primarySport = isBasketballAthlete || isFootballAthlete;
          const profileLink = getNILProfileLink({
            sport: sportName,
            slug: person?.slug,
          });

          return (
            <li className={styles.container} key={player.key}>
              <div className={styles.playerWrapper}>
                <div className={styles.playerContainer}>
                  <TextLink href={profileLink} underline="none">
                    <On3Avatar
                      altText={person?.defaultAsset?.title}
                      className={styles.playerAvatar}
                      domain={person?.defaultAsset?.domain}
                      height={50}
                      source={person?.defaultAsset?.source}
                      title={person?.defaultAsset?.title ?? ''}
                      width={50}
                    />
                  </TextLink>
                  <div className={styles.playerDetailsContainer}>
                    <div className={styles.associationContainer}>
                      {person?.division && (
                        <Text
                          className={styles.association}
                          variant="subtitle1"
                        >
                          {divisionAbbreviation(person?.division)}
                        </Text>
                      )}
                      <SportIcon
                        className={styles.sportIcon}
                        sportName={sportName}
                      />
                      <Text
                        className={clsx(styles.classRank, {
                          [styles.noClassRank]: !sportName,
                        })}
                        variant="subtitle1"
                      >
                        {person?.classRank
                          ? getAbbreviatedRank(person?.classRank)
                          : '-'}
                      </Text>
                    </div>
                    <div className={styles.playerNameWrapper}>
                      <TextLink
                        className={styles.playerName}
                        href={profileLink}
                        underline="none"
                      >
                        {person?.fullName}
                      </TextLink>
                      {person?.athleteVerified && <AthleteVerified />}
                    </div>

                    <div className={styles.playerVitalsContainer}>
                      <span aria-label="Position">
                        {person?.position?.abbr ?? '-'}
                      </span>
                      <span role="separator">/</span>
                      <span aria-label="Height">{person?.height ?? '-'}</span>
                      <span role="separator">/</span>
                      <span aria-label="Weight">{person?.weight ?? '-'}</span>
                    </div>
                    {primarySport && (
                      <div className={styles.ratingWrapper}>
                        {inCollege && (
                          <StarRating
                            fiveStarPlus={rosterRating?.fiveStarPlus}
                            href={`/db/${person?.slug}/industry-comparison/`}
                            isBoldRating={true}
                            isConsensus
                            rating={rosterRating?.rating}
                            rounding={2}
                            stars={rosterRating?.stars}
                            teamLogo={ratingTeamLogo}
                            year={`${rosterRating?.year}`}
                          />
                        )}
                        <StarRating
                          grayRating
                          href={`/db/${person?.slug}/industry-comparison/`}
                          isBoldRating={false}
                          isConsensus
                          rating={rating?.consensusRating}
                          small
                          stars={rating?.consensusStars}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.nilValueContainer}>
                  <Text className={styles.nilValue} variant="subtitle1">
                    <span className={styles.label}>NIL Value</span>
                    <span className={styles.nilValueText}>
                      {nilValue ? (
                        formatCurrency(nilValue)
                      ) : (
                        <span className={styles.valuationUndisclosed}>
                          undisclosed
                        </span>
                      )}
                    </span>
                  </Text>
                </div>
              </div>
              <div className={styles.companyContainer}>
                {company?.name && (
                  <div className={styles.companyWrapper}>
                    <Text variant="h5">{company?.name}</Text>
                    <Text className={styles.date} variant="caption">
                      {date ? format(new Date(date ?? '-'), 'M/dd/yy') : '-'}
                    </Text>
                  </div>
                )}
                {sourceUrl && (
                  <TextLink
                    className={clsx(styles.source, {
                      [styles.justSource]: !company?.name,
                    })}
                    href={sourceUrl}
                    underline="none"
                    variant="subtitle1"
                  >
                    {getSourceUrl(sourceUrl ?? '', currentSite?.primaryColor)}
                    Source
                  </TextLink>
                )}
              </div>
            </li>
          );
        })}
      </ul>
      <footer>
        {viewAllLink && (
          <TextLink
            className={styles.viewAll}
            href={`${viewAllLink}`}
            underline="none"
            variant="subtitle1"
          >
            Deal Tracker
          </TextLink>
        )}
      </footer>
    </SidebarWrapper>
  );
};
