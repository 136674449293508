import type { SVGProps } from 'react';

export const UsaTodayLogo = ({
  className,
  fill = '#009dff',
  ...svgProps
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className={className}
      height="1em"
      id="usa-today_svg__Layer_1"
      viewBox="0 0 2000 2000"
      width="1em"
      x={0}
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
      y={0}
      {...svgProps}
    >
      <style>{'.usa-today_svg__st1{fill:#fff}'}</style>
      <path
        d="M1931.6 994.8c0 518.8-420.6 939.4-939.5 939.4-518.8 0-939.4-420.6-939.4-939.4S473.3 55.4 992.1 55.4c518.9 0 939.5 420.6 939.5 939.4"
        fill={fill}
        id="usa-today_svg__path6918"
      />
      <path
        className="usa-today_svg__st1"
        d="M424.6 917.5c-31.9 0-49.6-19.5-49.6-53.9V689h-79v174.1c0 76.5 46.7 120.1 128.6 120.1s128.6-43.7 128.6-120.1V689h-79v174.6c0 34.4-17.8 53.9-49.6 53.9z"
        id="usa-today_svg__path42590"
      />
      <path
        className="usa-today_svg__st1"
        d="M692.6 983.3c69.2 0 114.6-36.9 114.6-91.7 0-39.1-23.4-69.2-85.4-86.2l-21.7-6c-31-8.5-42.4-17.8-42.4-31.4 0-14.4 12.7-22.5 35.2-22.5 27.3 0 56.5 15.3 76.9 32.7l39.4-49.6c-31-28.1-72.6-45-116.7-45-66.7 0-113.4 36.1-113.4 91.7 0 42.9 28 71 79.8 84.5l17.9 4.7c38.6 10.2 51.8 17.9 51.8 33.1 0 15.7-13.6 23.7-36.6 23.7-33.9 0-62-12.3-84.9-32.7l-38.2 48c32 29.7 76.6 46.7 123.7 46.7z"
        id="usa-today_svg__path42586"
      />
      <path
        className="usa-today_svg__st1"
        d="M1032.7 977.8h80.3L1007.2 689h-91.7L809.8 977.8h78.1l18.3-53.9h108.2l18.3 53.9zM926.1 865.2l34.4-101 33.9 101h-68.3z"
        id="usa-today_svg__path42582"
      />
      <path
        className="usa-today_svg__st1"
        d="M373.1 1327.8h82.2v-225h89.4V1039H283.8v63.7h89.3v225.1z"
        id="usa-today_svg__path42578"
      />
      <path
        className="usa-today_svg__st1"
        d="M698.8 1333.3c90.1 0 156-61.6 156-149.5 0-87.8-65.9-150.3-156-150.3-90.2 0-156.1 62.5-156.1 150.3 0 87.9 65.9 149.5 156.1 149.5zm0-65.4c-42.9 0-72.6-34-72.6-84.1 0-50.1 29.7-84.9 72.6-84.9 42.9 0 72.5 34.8 72.5 84.9 0 50.1-29.6 84.1-72.5 84.1z"
        id="usa-today_svg__path42570"
      />
      <path
        className="usa-today_svg__st1"
        d="M883.3 1327.8h118.4c88.9 0 152.2-59.5 152.2-144.3 0-84.9-63.3-144.4-152.2-144.4H883.3v288.7zm80.9-62.4v-163.9h37.5c43.3 0 69.9 32.7 69.9 82 0 49.2-26.6 82-69.9 82h-37.5z"
        id="usa-today_svg__path42566"
      />
      <path
        className="usa-today_svg__st1"
        d="M1370.8 1327.8h83.7L1344.3 1039h-95.5l-110.1 288.8h81.3l19.1-53.9h112.7l19 53.9zm-110.9-112.6 35.8-101 35.3 101h-71.1z"
        id="usa-today_svg__path42558"
      />
      <path
        className="usa-today_svg__st1"
        d="m1588.4 1223.8 106.5-184.8h-92.8l-55.3 114.6-55.7-114.6h-92.9l106.6 184.8v104h83.6v-104z"
        id="usa-today_svg__path42562"
      />
    </svg>
  );
};
