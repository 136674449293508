import { Facebook } from 'components/Svg/Facebook';
import { Instagram } from 'components/Svg/Instagram';
import { TikTok } from 'components/Svg/TikTok';
import { Twitter } from 'components/Svg/Twitter';
import { UsaTodayLogo } from 'components/Svg/UsaTodayLogo';

import styles from './DealTrackerItem.module.scss';

export const getSourceUrl = (
  sourceUrl?: string,
  primaryColor: string | null = '#fe3b1f',
) => {
  const defaultColor = primaryColor ?? '#fe3b1f';

  if (sourceUrl?.includes('twitter.com') || sourceUrl?.includes('x.com')) {
    return <Twitter className={styles.icon} fill={defaultColor} />;
  }

  if (sourceUrl?.includes('tiktok.com')) {
    return <TikTok className={styles.icon} fill={defaultColor} />;
  }

  if (sourceUrl?.includes('instagram.com')) {
    return <Instagram className={styles.icon} fill={defaultColor} />;
  }

  if (sourceUrl?.includes('facebook.com')) {
    return <Facebook className={styles.icon} fill={defaultColor} />;
  }

  if (sourceUrl?.includes('usatoday.com')) {
    return <UsaTodayLogo className={styles.icon} fill={defaultColor} />;
  }
};
